import axios from 'axios';

// API settings
const API_URL = ''; // Change it as needed
const REQ_TIMEOUT = 15000; // 15 sec

// Function to add a user
export async function loginWithGoogle(token, organization) {
  return axios({
    method: 'post',
    url: `${API_URL}/login/google`,
    data: {
      token,
      organization: organization ? organization.id : ''
    },
    timeout: REQ_TIMEOUT
  });
}

// Function to request a password reset e-mail
export async function resetPasswordEmail(email) {
  return axios({
    method: 'post',
    url: `${API_URL}/resetpassword/email`,
    data: {
      email
    },
    timeout: REQ_TIMEOUT
  });
}

// Function to request a password reset e-mail
export async function resetPasswordEmailValidCode(email, code) {
  return axios({
    method: 'post',
    url: `${API_URL}/resetpassword/email/validcode`,
    data: {
      email,
      code
    },
    timeout: REQ_TIMEOUT
  });
}

/**
 * Function to set new password sending e-mail, code and new password
 *
 * @param {String} email
 * @param {String} code
 * @param {String} password
 *
 * @return {Promise}
 */
export async function resetPasswordEmailNewPassword(email, code, password) {
  return axios({
    method: 'post',
    url: `${API_URL}/resetpassword/email/update`,
    data: {
      email,
      code,
      password
    },
    timeout: REQ_TIMEOUT
  });
}

/**
 * Functiont o update user's image
 *
 * @param {Object} objFile
 * @param {Function} onProgress
 *
 * @return {Promise}
 */
export async function updateUserAvatar(objFile, onProgress) {
  const formData = new FormData();
  formData.append('file', objFile);
  return axios.post(`${API_URL}/api/panel/user/updateavatar`, formData, {
    onUploadProgress: onProgress,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

/**
 * Function to update an user's details
 *
 * @param {Object} objUpdate
 * @return {Promise}
 */
export async function updateUserDetails(objUpdate) {
  return axios({
    method: 'patch',
    url: `${API_URL}/api/panel/me/details`,
    data: { update: objUpdate },
    timeout: REQ_TIMEOUT
  });
}

/**
 * function to update current user's password
 *
 * @param {String} oldPass
 * @param {String} newPass
 *
 * @return {Promise}
 */
export async function updateUserPassword(oldPass, newPass) {
  return axios({
    method: 'patch',
    url: `${API_URL}/api/panel/me/password`,
    data: { oldpassword: oldPass, newpassword: newPass },
    timeout: REQ_TIMEOUT
  });
}

/**
 * Function to update organization's image
 *
 * @param {Object} objFile
 * @param {Function} onProgress
 *
 * @return {Promise}
 */
export async function updateOrgImage(objFile, onProgress) {
  const formData = new FormData();
  formData.append('file', objFile);
  return axios.post(`${API_URL}/api/panel/organization/image`, formData, {
    onUploadProgress: onProgress,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

/**
 * Function to update an organization details
 *
 * @param {Object} objUpdate
 * @return {Promise}
 */
export async function updateOrganization(objUpdate) {
  return axios({
    method: 'patch',
    url: `${API_URL}/api/panel/organization/details`,
    data: { update: objUpdate },
    timeout: REQ_TIMEOUT
  });
}

/**
 * Function to update organization's settings map
 *
 * @param {*} objUpdate
 * @return {Promise}
 */
export async function updateOrganizationSettingsMap(objUpdate) {
  return axios({
    method: 'patch',
    url: `${API_URL}/api/panel/organization/settings/map`,
    data: { update: objUpdate },
    timeout: REQ_TIMEOUT
  });
}

/**
 * Function to get curent organization's settings map
 * @return {Promise}
 */
export async function getOrganizationSettingsMap() {
  return axios.get(`${API_URL}/api/panel/organization/settings/map`);
}

// Function to add a role
export async function addRole(name) {
  return axios({
    method: 'post',
    url: `${API_URL}/api/panel/organization/roles`,
    data: {
      name: name
    },
    timeout: REQ_TIMEOUT
  });
}

// Function to add a new area/group
export async function addArea(name) {
  return axios({
    method: 'post',
    url: `${API_URL}/api/panel/organization/areas`,
    data: {
      name: name
    },
    timeout: REQ_TIMEOUT
  });
}

// Function to add a user
export async function addUser(
  roleId,
  arrTeamsIds,
  name,
  lastname,
  email,
  phonenumber
) {
  return axios({
    method: 'post',
    url: `${API_URL}/api/panel/user/add`,
    data: {
      role: roleId,
      teamsids: arrTeamsIds,
      name: name,
      lastname: lastname,
      email: email,
      phonenumber: phonenumber
    },
    timeout: REQ_TIMEOUT
  });
}

/**
 * Function to resend an e-mail invite
 *
 * @param {Int} userId
 * @return {Promise}
 */
export async function resendEmailInvite(userId) {
  return axios({
    method: 'post',
    url: `${API_URL}/api/panel/user/resendemailinvite`,
    data: {
      userid: userId
    },
    timeout: REQ_TIMEOUT
  });
}

// Function to update a user from /org/users (admin users)
export async function updateOrgUser(userId, objUpdate) {
  return axios({
    method: 'patch',
    url: `${API_URL}/api/panel/organization/users/details`,
    data: { userid: userId, update: objUpdate },
    timeout: REQ_TIMEOUT
  });
}

// Function to delete a user
export async function deleteUser(userId) {
  return axios.delete(`${API_URL}/api/panel/user/delete/${userId}`);
}

// Function to delete a user
export async function deleteRole(roleId) {
  return axios.delete(`${API_URL}/api/panel/organization/roles/${roleId}`);
}

// Function to delete a user
export async function getRoleModules(roleId) {
  return axios.get(`${API_URL}/api/panel/organization/roles/${roleId}/modules`);
}

// Function to add a module to a role
export async function addRoleModule(roleId, moduleId) {
  return axios({
    method: 'post',
    url: `${API_URL}/api/panel/organization/roles/module`,
    data: {
      roleid: roleId,
      moduleid: moduleId
    },
    timeout: REQ_TIMEOUT
  });
}

// Function to delete a role from a module
export async function deleteRoleModule(roleModuleId) {
  return axios.delete(
    `${API_URL}/api/panel/organization/roles/modules/${roleModuleId}`
  );
}

// Converting lat/long from browser geolocation into city, state, and zip code using Google Geocoding API
export function geocodeLocation(lat, long) {
  const getURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&sensor=true&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}`;

  return axios.get(getURL);
}
